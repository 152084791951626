import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

export default {
    ...novelaTheme,
    initialColorMode: `dark`,
    fonts: {
        sansSerif: "'Open Sans', 'Comfortaa', 'Ubuntu', 'Helvetica Neue', Helvetica, 'Segoe UI Light', 'Segoe UI', Arial, sans-serif"
    },
    colors: {
        ...novelaTheme.colors,
        primary: '#000',
        secondary: '#73737D',
        accent: '#ddb888',
        silver: '#bbb',
        grey: '#73737D',
        background: '#fff',
        hover: 'rgba(255,255,255,0.05)',
        modes: {
            dark: {
                grey: "#73737D",
                primary: "#fff",
                secondary: "#fff",
                accent: "#E9DAAC",
                background: "#000",
                hover: "rgba(255, 255, 255, 0.07)",
                gradient:
                    "linear-gradient(180deg, #111216 0%, rgba(66, 81, 98, 0.36) 100%)",
                articleText: "#fff",
                track: "rgba(255, 255, 255, 0.3)",
                progress: "#fff",
                card: "#060606",
                error: "#EE565B",
                success: "#46B17B",
                errorBackground: "rgba(238, 86, 91, 0.1)",
                horizontalRule: "rgba(255, 255, 255, 0.15)",
                inputBackground: "rgba(255, 255, 255, 0.07)",
            },
        }
    },
    transition: 'ease-in-out 150ms'
};